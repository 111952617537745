// ** React Imports
// import 'react-app-polyfill/ie9';
// import 'react-app-polyfill/stable';
import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";

// ** Intl, CASL & ThemeColors Context
import ability from "./configs/acl/ability";
import { Slide, ToastContainer } from "react-toastify";
import { AbilityContext } from "./utility/context/Can";
import { ThemeContext } from "./utility/context/ThemeColors";
import { IntlProviderWrapper } from "./utility/context/Internationalization";
import ErrorBoundary from './error-boundary'
// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** Fake Database
import "./@fake-db";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

// ** Service Worker
import * as serviceWorker from "./serviceWorker";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
// ** Lazy load app
const LazyApp = lazy(() => import("./App"));
// const ability = [{action: "manage", subject: "all"}]

ReactDOM.render(
  // <ErrorBoundary>
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <AbilityContext.Provider value={ability}>
        <ThemeContext>
          <IntlProviderWrapper>
            <LazyApp />
            <ToastContainer
              //  newestOnTop
              theme="dark"
              position="top-right"
              autoClose={3000}
              transition={Slide}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              limit={2}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </IntlProviderWrapper>
        </ThemeContext>
      </AbilityContext.Provider>
    </Suspense>
  </Provider>
  // </ErrorBoundary>
  ,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
